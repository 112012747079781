import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setTokenCookie = (key: string, value: string) => {
    const isProd = process.env.NODE_ENV === 'production';
    cookies.set(key, value,
        {
            maxAge: key === 'sc-at' ? 86400 : 604800, //refresh token keep for a week
            domain: isProd ? window.location.hostname : 'localhost',
            secure: isProd ? true : undefined,
            sameSite: isProd ? 'lax' : undefined,
            path: '/'
        });
}