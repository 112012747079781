import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSpinner from '../../hooks/layout/useSpinner';
import { CHART_STATISTIC, TOTAL_STATISTIC } from './Dashboard.gql';
import './Dashboard.less';
import { Row, Col, Card, Select } from 'antd';
import Title from 'antd/lib/typography/Title';
import { formatNumber } from '../../shared/helpers/number.helper';
import ColumnChartView from './DashboardChartView';
import { randomKeyString } from '../../shared/helpers/json-display-helper';
import { useAuth } from '../../hooks/auth/AuthContext';
import { GET_SIMPLY_MERCHANTS } from '../merchants/Merchant.gql';
import { useNavigate } from 'react-router-dom';
import { getTime } from 'date-fns';


const Dashboard = () => {
  const transMethod = ['FPX', 'TNG', 'CRYPTO', 'QR', 'P2P']; // AddTransType
  const intl = useIntl();
  const navigate = useNavigate();
  const { setLoading } = useSpinner();
  const { authState } = useAuth();
  const [currentMerchant, setCurrentMerchant] = useState<any>();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<any>('FPX');
  const [simplyMerchants, setSimplyMerchants] = useState<any>();
  const [getSimplyMerchant, { data: merchantData }] = useLazyQuery(GET_SIMPLY_MERCHANTS);
  const [totals, setTotals] = useState<any>();
  const [charts, setCharts] = useState<any>();
  const [loadTotalData, { data: totalStatistic, loading: totalLoading }] = useLazyQuery(TOTAL_STATISTIC, { fetchPolicy: 'no-cache' });
  const [loadChartData, { data: chartStatistic, loading: chartLoading }] = useLazyQuery(CHART_STATISTIC, { fetchPolicy: 'no-cache' });

  const { Option } = Select;
  const colDDL = { xs: 24, sm: 24, md: 24, lg: 5, xl: 5 };
  const colCard = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 };
  const colChart = { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 };


  useEffect(() => {
    if (!!authState?.userProfile) {
      if (authState?.userProfile?.role !== "Merchant") {
        loadTotalData({ variables: { paymentMethod: currentPaymentMethod } });
        loadChartData({ variables: { paymentMethod: currentPaymentMethod } });
      } else getSimplyMerchant({ variables: { isMerchant: authState?.userProfile?.role === 'Merchant' } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.userProfile]);

  useEffect(() => {
    if (totalLoading)
      setLoading(true);
    else
      setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalLoading]);

  useEffect(() => {
    if (!!merchantData) {
      if (authState?.userProfile?.role === 'Merchant') {
        setSimplyMerchants(merchantData.simplyMerchants);
        if (merchantData.simplyMerchants.length > 0) {
          const merchantId: string = merchantData.simplyMerchants[0]?.id;
          setCurrentMerchant(merchantId);
          loadTotalData({ variables: { merchantId, paymentMethod: currentPaymentMethod } });
          loadChartData({ variables: { merchantId, paymentMethod: currentPaymentMethod } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantData]);

  useEffect(() => {
    if (!!totalStatistic) { setTotals(totalStatistic.dashboardTotalStatistic); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalStatistic]);

  useEffect(() => {
    if (!!chartStatistic) { setCharts(chartStatistic.dashboardStatistic) };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartStatistic]);

  const merchantChange = (e: any) => {
    setCurrentMerchant(e);
    let variables: any = { paymentMethod: currentPaymentMethod };
    if (!!e) variables.merchantId = e;

    loadTotalData({ variables: variables });
    loadChartData({ variables: variables });
  }
  const paymentMethodChange = (e: any) => {
    setCurrentPaymentMethod(e);
    let variables: any = { paymentMethod: e };
    if (!!currentMerchant) variables.merchantId = currentMerchant;

    loadTotalData({ variables: variables });
    loadChartData({ variables: variables });
  }


  const renderMerchantDD = () => {
    const options = !!simplyMerchants && simplyMerchants.map((item: any, index: any) => {
      return <Option key={item?.id} value={item?.id}>{item?.name}</Option>;
    });
    return <Col {...colDDL} className="merchant-select">
      <Select placeholder={intl.formatMessage({ id: 'FORM_PLEASE_SELECT' }, { 0: intl.formatMessage({ id: 'MERCHANTS' }) })}
        onChange={merchantChange} value={currentMerchant}>
        {options}
      </Select>
    </Col>;
  }
  const renderPaymentMethodDD = () => {

    return <Col {...colDDL} className="pymt-method-select">
      <Select placeholder={intl.formatMessage({ id: 'FORM_PLEASE_SELECT' }, { 0: intl.formatMessage({ id: 'PAYMENT_TYPE' }) })} onChange={paymentMethodChange} value={currentPaymentMethod}>
        {transMethod.map((item: any, index: any) => {
          return <Option key={`${getTime(new Date())}${index}`} value={item}><FormattedMessage id={item} /></Option>;
        })}
      </Select>
    </Col>;
  }

  const renderCard = (colDetail: any, content: any, redirect?: any) => {
    return <Col {...colDetail} key={randomKeyString()} onClick={redirect} className={!!redirect ? 'navigate-option' : ''}>
      <Card key={randomKeyString()} className='dashboard-card'>
        {content}
      </Card>
    </Col>;
  }

  const cardDetail = (item: any, title: string) => {
    if (!!item) return <>
      <div className='main-title'><Title level={4}><FormattedMessage id={title} /></Title><span>{item.range}</span></div>
      <div className='text-right text-20'><b>{formatNumber(intl, item.totalSuccessAmount ?? 0)}</b></div>
      <div className='custom-divider'></div>
      <div className='total-record'>
        <FormattedMessage id='VOLUME' />
        <b>{item.totalSuccessRecord ?? 0}/{item.totalRecord ?? 0}</b>
      </div>
      <div className='text-right'></div></>;
    else return null;
  }

  const renderTotalPart = () => {
    let _elements: any = <></>;
    if (!!totals) {
      const { merchant, payIn, payOut, settlement } = totals;
      const cardList: any = [];
      if (!!merchant) cardList.push({
        element: <>
          <div><b><FormattedMessage id='AVAILABLE_BALANCE' /></b></div>
          <div className='text-right text-20'><b>{formatNumber(intl, merchant.availableBalance ?? 0)}</b></div>
          <div className='custom-divider'></div>
          <div><FormattedMessage id='BALANCE' /></div>
          <div className='text-right'><b>{formatNumber(intl, merchant.balance ?? 0)}</b></div>
        </>,
        redirect: () => navigate(`/merchants/balance-history?merchantId=${merchant.id}`)
      });

      if (!!payIn) {
        const payInDaily = cardDetail(payIn?.daily, 'PAY_IN'), payInWeekly = cardDetail(payIn?.weekly, 'PAY_IN');
        if (!!payInDaily) cardList.push({ element: payInDaily, redirect: undefined });
        if (!!payInWeekly) cardList.push({ element: payInWeekly, redirect: undefined });
      }

      if (!!payOut) {
        const payOutDaily = cardDetail(payOut?.daily, 'PAY_OUT'), payOutWeekly = cardDetail(payOut?.weekly, 'PAY_OUT');
        if (!!payOutDaily) cardList.push({ element: payOutDaily, redirect: undefined });
        if (!!payOutWeekly) cardList.push({ element: payOutWeekly, redirect: undefined });
      }

      if (!!settlement) {
        const settlementDaily = cardDetail(settlement?.daily, 'SETTLEMENT'), settlementWeekly = cardDetail(settlement?.weekly, 'SETTLEMENT');
        if (!!settlementDaily) cardList.push({ element: settlementDaily, redirect: undefined });
        if (!!settlementWeekly) cardList.push({ element: settlementWeekly, redirect: undefined });
      }

      _elements = <>
        <Row gutter={[16, 16]}>
          {cardList.map((x: any) => renderCard(colCard, x.element, x.redirect))}
        </Row>
      </>;
    }

    return _elements;
  }

  const renderChartPart = () => {
    let _elements: any = <></>;
    if (!!charts) {

      _elements = <>
        <Row gutter={[16, 16]} className='chart-part'>
          <Col {...colChart}>
            <ColumnChartView title={intl.formatMessage({ id: 'WEEKLY_CHART' }, { 0: intl.formatMessage({ id: 'PAY_IN' }) })} data={charts.payIn || []}
              xFieldName="date" yFieldName="amount" index={0} loading={chartLoading} />
          </Col>
          <Col {...colChart}>
            <ColumnChartView title={intl.formatMessage({ id: 'WEEKLY_CHART' }, { 0: intl.formatMessage({ id: 'PAY_OUT' }) })} data={charts.payOut || []}
              xFieldName="date" yFieldName="amount" index={0} loading={chartLoading} />
          </Col>
          <Col {...colChart}>
            <ColumnChartView title={intl.formatMessage({ id: 'WEEKLY_CHART' }, { 0: intl.formatMessage({ id: 'SETTLEMENT' }) })} data={charts.settlement || []}
              xFieldName="date" yFieldName="amount" index={0} loading={chartLoading} />
          </Col>
        </Row>
      </>;
    }
    return _elements;
  }

  return (
    <>

      <Row gutter={[8, 8]}>
        {!!simplyMerchants && renderMerchantDD()}
        {renderPaymentMethodDD()}
      </Row>
      {renderTotalPart()}
      {renderChartPart()}
    </>
  );
}

export default Dashboard;